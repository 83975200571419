// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import HeaderBackgroundImage from './background-image'
import { tablet, mobile } from 'shared/media-queries'

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		Simplify the bidding process and win more bids.
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Estimators save an average of 8 hours per week with Bid Board Pro’s power
		features.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading}
		subHeading={subHeading}
		wordingWidth="500px"
	/>
)

export default Header
