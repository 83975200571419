// @flow
import * as React from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	SectionBreak,
	ContentWrapper,
	Footer,
} from 'views/components'
import { cyan } from 'shared/colors'
import Header from './header'
import KeepTrack from './keep-track'
import Consolidate from './consolidate'
import BetterWithTeam from './better-with-team'
import SealTheDeal from './seal-the-deal'
import SendProposals from './send-proposals'
import ResourceHub from './resource-hub'
import useScreenWidth from 'hooks/use-screen-width'

const ManageBids = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Easily Manage Invitations to Bid | BuildingConnected"
			description="Simplify the bidding process and win more bids using Bid Board Pro's power features for subcontractors."
		>
			<Header screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<KeepTrack screenWidth={screenWidth} selectedDotColor={cyan} />
				<SectionBreak />
				<Consolidate />
				<SectionBreak />
				<BetterWithTeam screenWidth={screenWidth} />
				<SectionBreak />
				<SealTheDeal screenWidth={screenWidth} />
				<SectionBreak />
				<SendProposals screenWidth={screenWidth} />
				<SectionBreak />
				<ResourceHub />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Simplify your bidding process. Try Bid Board Pro with your team for free."
				showCreateAccount
			/>
		</Layout>
	)
}

export default ManageBids
