// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { breakpoints, laptop } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.laptop) {
		return '380px'
	}
	return '470px'
}

const BetterWithTeam = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		imageOnRight
		image={
			<Img
				src={image}
				srcSet={`${image2x} 2x, ${image3x} 3x`}
				display="block"
				css={{
					[laptop]: { width: '100%' },
				}}
			/>
		}
		wordingWidth={getWordingWidth(screenWidth)}
		subtitle="Send proposals and know when GCs view your bid."
		info="Easily send proposals from the same place you’re managing your entire bidding process and get notified as soon as GCs view your bid."
		spacerWidths={['80px', '20px']}
		centered={screenWidth < breakpoints.laptop}
		verticallyCentered
		showNewBadge
	/>
)

export default BetterWithTeam
