// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { CSSTransition } from 'react-transition-group'

import Spacer from 'components/spacer'
import DotsWithArrowsControl from './dots-with-arrows-control'

type Props = {
	content: Array<{
		image: React.Node,
		text?: React.Node,
	}>,
	selectedDotColor: string,
	cycleMs?: number,
}
type State = { currentIndex: number }

class ImageCarousel extends React.Component<Props, State> {
	state = { currentIndex: 0 }

	render() {
		const { content, selectedDotColor } = this.props
		const { currentIndex } = this.state

		return (
			<Div>
				<Div position="relative">
					{content.map(({ image, text }, i) => (
						<CSSTransition
							in={i === currentIndex}
							timeout={500}
							classNames="image-carousel"
							key={i}
						>
							<Div
								opacity={i === currentIndex ? '1' : '0'}
								width="100%"
								position="absolute"
								backgroundColor="white" // To allow text to fade correctly
								css={{
									'&.image-carousel-enter': {
										opacity: 0,
										transition: 'opacity 500ms',
									},
									'&.image-carousel-enter-active': {
										// Restarting at first image causes entering image to be behind exiting,
										// covering the animation so we fix with zindex
										zIndex: 1,
										opacity: 1,
										transition: 'opacity 500ms',
									},
									'&.image-carousel-exit': {
										opacity: 1,
									},
									'&.image-carousel-exit-done': {
										opacity: 0,
									},
								}}
							>
								{image}
								{!!text && (
									<>
										<Div height="10px" />
										{text}
									</>
								)}
							</Div>
						</CSSTransition>
					))}
				</Div>

				{/* This forces the container to take the height of the image even though the image is
				{/* absolutely posisitoned in order to allow them to appear one on top of the other*/}
				<Div visibility="hidden">{content[currentIndex].image}</Div>
				<Div visibility="hidden">{content[currentIndex].text}</Div>

				<Spacer height="30px" />
				<DotsWithArrowsControl
					changeSection={this.clickButton}
					totalLength={content.length}
					openIndex={currentIndex}
					selectedColor={selectedDotColor}
				/>
			</Div>
		)
	}

	componentDidMount() {
		this.setCycleInterval()
	}

	componentWillUnmount() {
		this.clearCycleInterval()
	}

	cycleInterval = null

	setCycleInterval = () => {
		const { cycleMs } = this.props
		if (cycleMs) {
			this.cycleInterval = setInterval(() => this.clickButton(1), cycleMs)
		}
	}

	clearCycleInterval = () => {
		if (this.cycleInterval) {
			clearInterval(this.cycleInterval)
		}
	}

	clickButton = (delta: -1 | 1) => {
		const { content } = this.props

		this.setState(state => {
			let newIndex = state.currentIndex + delta

			if (newIndex > content.length - 1) {
				newIndex = 0
			} else if (newIndex < 0) {
				newIndex = content.length - 1
			}

			return { currentIndex: newIndex }
		})

		// Restart interval on user interaction with carousel
		this.clearCycleInterval()
		this.setCycleInterval()
	}
}

export default ImageCarousel
