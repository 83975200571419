// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { SectionInfo } from 'views/components'
import ImagesCarousel from 'views/components/carousel/images-carousel'
import { breakpoints } from 'shared/media-queries'
import { desktopContent, tabletContent, mobileContent } from './content'

const getContent = screenWidth => {
	if (screenWidth <= breakpoints.mobile) {
		return mobileContent
	} else if (screenWidth <= breakpoints.tablet) {
		return tabletContent
	}
	return desktopContent
}

const KeepTrack = ({
	screenWidth,
	selectedDotColor,
}: {
	screenWidth: number,
	selectedDotColor: string,
}) => (
	<Div>
		<Div maxWidth="800px" margin="0 auto">
			<SectionInfo
				centered
				subtitle="Keep track of all your bids at every stage."
				info="No more digging through emails and spreadsheets to find the status of a bid. Bid Board Pro keeps all your bids organized and streamlined in one place."
			/>
		</Div>
		<Div height="50px" />
		<ImagesCarousel
			content={getContent(screenWidth)}
			cycleMs={10000}
			selectedDotColor={selectedDotColor}
		/>
	</Div>
)

export default KeepTrack
