// @flow
import * as React from 'react'
import { Img, Div, A } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video.png'
import videoImage2x from './img/video@2x.png'
import videoImage3x from './img/video@3x.png'
import blogImage from './img/blog.png'
import blogImage2x from './img/blog@2x.png'
import blogImage3x from './img/blog@3x.png'
import { navy } from 'shared/colors'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: 'How to easily manage all your bids with Bid Board Pro.',
		cta: 'Watch the video',
		videoId: '02ae8hkt3n',
	},
	{
		image: (
			<Img
				width="100%"
				src={blogImage}
				srcSet={`${blogImage2x} 2x, ${blogImage3x} 3x`}
			/>
		),
		title: 'Blog',
		wording: `How to increase the productivity of your estimating team.`,
		cta: 'Read more',
		href: `https://blog.buildingconnected.com/how-to-increase-the-productivity-of-your-estimating-team/`,
	},
	{
		title: 'How To',
		wording: (
			<Div>
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360010100254-How-subcontractors-can-assign-and-unassign-coworkers-to-bids-Bid-Board-Pro-"
					target="_blank"
				>
					How to assign coworkers to bids.
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360010222793-How-to-submit-your-bid-through-BuildingConnected-"
					target="_blank"
				>
					How to submit proposals through BuildingConnected.
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360016885614-How-subcontractors-can-add-follow-up-dates-to-submitted-bids-Bid-Board-Pro-"
					target="_blank"
				>
					How to add follow up dates to submitted bids.
				</A>
			</Div>
		),
	},
]

const PricingResourceHub = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="All the information you need to manage your bids like a pro."
		cards={cards}
	/>
)

export default PricingResourceHub
