// @flow
import * as React from 'react'
import { Img, Br } from 'glamorous'

import { InfoAndImage } from 'views/components'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { breakpoints, laptop, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.laptop) {
		return '405px'
	}
	return '480px'
}

const Subtitle = () => (
	<div>
		Seal the deal with <Br css={{ [tablet]: { display: 'none' } }} />
		follow-ups.
	</div>
)

const BetterWithTeam = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		image={
			<Img
				src={image}
				srcSet={`${image2x} 2x, ${image3x} 3x`}
				display="block"
				css={{
					[laptop]: { width: '100%' },
				}}
			/>
		}
		wordingWidth={getWordingWidth(screenWidth)}
		subtitle={<Subtitle />}
		info="With Bid Board Pro you can easily set up follow-up dates, so you won’t forget to follow up with GCs—whether it be for a job walk or after submitting a bid."
		bulletItems={[
			'Set follow-up dates for each bid',
			'Track and view follow-up dates on your bid calendar',
			'Sync follow-up dates to your personal calendar',
		]}
		spacerWidths={['120px', '30px']}
		centered={screenWidth < breakpoints.laptop}
	/>
)

export default BetterWithTeam
