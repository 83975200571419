// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { breakpoints } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth <= breakpoints.laptop) {
		return '420px'
	}
	return '480px'
}

const BetterWithTeam = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		image={
			<Img
				src={image}
				srcSet={`${image2x} 2x, ${image3x} 3x`}
				display="block"
				width="100%"
			/>
		}
		imageOnRight
		wordingWidth={getWordingWidth(screenWidth)}
		subtitle="Work better with your team."
		info="Commenting on bids improves communication with your team so everyone stays on the same page."
		bulletItems={[
			'Leave time-stamped comments on your bid board, calendar and reports',
			'Keep an updated track record for every bid you work on',
			'Get visibility across your team, so everyone knows where every bid stands',
		]}
		spacerWidths={['120px', '20px']}
		centered={screenWidth < breakpoints.laptop}
	/>
)

export default BetterWithTeam
