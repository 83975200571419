// @flow
import * as React from 'react'
import glamorous, { Div, Img } from 'glamorous'

import { H2 } from 'views/components'
import { grayText } from 'shared/colors'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { laptop, tablet, mobile, smallerThan } from 'shared/media-queries'

const Header = glamorous.div({ fontSize: '20px', fontWeight: '700' })
const Text = glamorous.div({ fontSize: '18px', color: grayText })

const Consolidate = () => (
	<Div>
		<H2
			maxWidth="510px"
			css={{
				[tablet]: { maxWidth: 'none' },
			}}
		>
			Consolidate your bids and know who’s working on what.
		</H2>
		<Div height="30px" />
		<Div
			display="flex"
			css={{
				[tablet]: { flexDirection: 'column' },
			}}
		>
			<Div
				flex="1 1 0%"
				css={{
					[tablet]: { flex: '0 0 auto' },
				}}
			>
				<Header>Group bids for peace of mind</Header>
				<Div height="10px" />
				<Text>
					Managing multiple invites for the same project from different GCs with
					a manual bid board can be chaotic. Bid Board Pro groups the same
					project from different GCs into one, for easy tracking. Create one
					proposal and submit to each GC on the project.
				</Text>
			</Div>
			<Div flex="0 0 30px" />
			<Div
				flex="1 1 0%"
				css={{
					[tablet]: { flex: '0 0 auto' },
				}}
			>
				<Header>Assign bids to stop double-work</Header>
				<Div height="10px" />
				<Text>
					Having no visibility into who’s working on what can put two estimators
					on the same job unintentionally. With Bid Board Pro, you can assign
					bids to the right team member and eliminate double-work.
				</Text>
			</Div>
		</Div>
		<Div
			height="60px"
			css={{
				[laptop]: { height: '30px' },
			}}
		/>
		<Img
			src={image}
			srcSet={`${image2x} 2x, ${image3x} 3x`}
			display="block"
			width="100%"
			css={{
				[tablet]: { width: 'auto', height: '275px' },
				[mobile]: { height: '215px' },
				[smallerThan(320)]: { height: '155px' },
			}}
		/>
	</Div>
)

export default Consolidate
