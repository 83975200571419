// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import firstSlide from './img/1.png'
import firstSlide2x from './img/1@2x.png'
import firstSlide3x from './img/1@3x.png'
import secondSlide from './img/2.png'
import secondSlide2x from './img/2@2x.png'
import secondSlide3x from './img/2@3x.png'
import thirdSlide from './img/3.png'
import thirdSlide2x from './img/3@2x.png'
import thirdSlide3x from './img/3@3x.png'
import fourthSlide from './img/4.png'
import fourthSlide2x from './img/4@2x.png'
import fourthSlide3x from './img/4@3x.png'

export const desktopContent = [
	{
		image: (
			<Img
				src={firstSlide}
				srcSet={`${firstSlide2x} 2x, ${firstSlide3x} 3x`}
				width="100%"
			/>
		),
	},
	{
		image: (
			<Img
				src={secondSlide}
				srcSet={`${secondSlide2x} 2x, ${secondSlide3x} 3x`}
				width="100%"
			/>
		),
	},
	{
		image: (
			<Img
				src={thirdSlide}
				srcSet={`${thirdSlide2x} 2x, ${thirdSlide3x} 3x`}
				width="100%"
			/>
		),
	},
	{
		image: (
			<Img
				src={fourthSlide}
				srcSet={`${fourthSlide2x} 2x, ${fourthSlide3x} 3x`}
				width="100%"
			/>
		),
	},
]

import tabletFirstSlide from './img/tablet-1.png'
import tabletFirstSlide2x from './img/tablet-1@2x.png'
import tabletFirstSlide3x from './img/tablet-1@3x.png'
import tabletSecondSlide from './img/tablet-2.png'
import tabletSecondSlide2x from './img/tablet-2@2x.png'
import tabletSecondSlide3x from './img/tablet-2@3x.png'
import tabletThirdSlide from './img/tablet-3.png'
import tabletThirdSlide2x from './img/tablet-3@2x.png'
import tabletThirdSlide3x from './img/tablet-3@3x.png'
import tabletFourthSlide from './img/tablet-4.png'
import tabletFourthSlide2x from './img/tablet-4@2x.png'
import tabletFourthSlide3x from './img/tablet-4@3x.png'
import { grayText } from 'shared/colors'

const TabletText = ({ title, wording }: { title: string, wording: string }) => (
	<Div textAlign="center">
		<Div fontWeight="600" letterSpacing="1px">
			{title}
		</Div>
		<Div height="10px" />
		<Div color={grayText}>{wording}</Div>
	</Div>
)

export const tabletContent = [
	{
		image: (
			<Img
				src={tabletFirstSlide}
				srcSet={`${tabletFirstSlide2x} 2x, ${tabletFirstSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<TabletText
				title="UNDECIDED"
				wording="Pick which bid invites you’ll work on."
			/>
		),
	},
	{
		image: (
			<Img
				src={tabletSecondSlide}
				srcSet={`${tabletSecondSlide2x} 2x, ${tabletSecondSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<TabletText
				title="ACCEPTED"
				wording="The projects you’re actively working on."
			/>
		),
	},
	{
		image: (
			<Img
				src={tabletThirdSlide}
				srcSet={`${tabletThirdSlide2x} 2x, ${tabletThirdSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<TabletText title="SUBMITTED" wording="Proposals that need follow-ups." />
		),
	},
	{
		image: (
			<Img
				src={tabletFourthSlide}
				srcSet={`${tabletFourthSlide2x} 2x, ${tabletFourthSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<TabletText
				title="WON"
				wording="See how you and your team are performing."
			/>
		),
	},
]

import mobileFirstSlide from './img/mobile-1.png'
import mobileFirstSlide2x from './img/mobile-1@2x.png'
import mobileFirstSlide3x from './img/mobile-1@3x.png'
import mobileSecondSlide from './img/mobile-2.png'
import mobileSecondSlide2x from './img/mobile-2@2x.png'
import mobileSecondSlide3x from './img/mobile-2@3x.png'
import mobileThirdSlide from './img/mobile-3.png'
import mobileThirdSlide2x from './img/mobile-3@2x.png'
import mobileThirdSlide3x from './img/mobile-3@3x.png'
import mobileFourthSlide from './img/mobile-4.png'
import mobileFourthSlide2x from './img/mobile-4@2x.png'
import mobileFourthSlide3x from './img/mobile-4@3x.png'

const MobileText = ({ title, wording }: { title: string, wording: string }) => (
	<Div textAlign="center">
		<Div fontWeight="600" letterSpacing="1px">
			{title}
		</Div>
		<Div height="10px" />
		<Div color={grayText} fontSize="16px">
			{wording}
		</Div>
	</Div>
)

export const mobileContent = [
	{
		image: (
			<Img
				src={mobileFirstSlide}
				srcSet={`${mobileFirstSlide2x} 2x, ${mobileFirstSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<MobileText
				title="UNDECIDED"
				wording="Pick which bid invites you’ll work on."
			/>
		),
	},
	{
		image: (
			<Img
				src={mobileSecondSlide}
				srcSet={`${mobileSecondSlide2x} 2x, ${mobileSecondSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<MobileText
				title="ACCEPTED"
				wording="The projects you’re actively working on."
			/>
		),
	},
	{
		image: (
			<Img
				src={mobileThirdSlide}
				srcSet={`${mobileThirdSlide2x} 2x, ${mobileThirdSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<MobileText title="SUBMITTED" wording="Proposals that need follow-ups." />
		),
	},
	{
		image: (
			<Img
				src={mobileFourthSlide}
				srcSet={`${mobileFourthSlide2x} 2x, ${mobileFourthSlide3x} 3x`}
				width="100%"
			/>
		),
		text: (
			<MobileText
				title="WON"
				wording="See how you and your team are performing."
			/>
		),
	},
]
